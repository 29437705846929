<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div>
        <b-alert
            variant="primary"
            show
        >
          <div class="alert-body">
            <!--            style="padding-left: 10px"-->
            <span>勾选产品，表示此产品需要入库</span>
          </div>
        </b-alert>

      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="dataList"
        >

          <!-- Columns -->

          <template #cell(id)="data">

          </template>

          <template #cell(name)="data">
            <b-form-checkbox
                name="id"
                value="1"
                unchecked-value="0"
                v-model="data.item.is_sel"
                @change="changeCheckBox(data.item)"
            >
              [#{{ data.item.id }}]{{ data.value }}
            </b-form-checkbox>
          </template>

          <!-- Columns -->
          <template #cell(product_code)="data">
            {{ data.item.ext.product.code }}

          </template>

          <template #cell(inventory_type)="data">
            <v-select
                id="inventory_type"
                :options="getCodeOptions('inbound_inventory_type')"
                :clearable="true"
                v-model="data.item.inventorytype"
                @input="changeSelect(data.item,$event)"
                class="select-size-sm"
            />

          </template>


          <!-- Columns -->
          <template #cell(qtyInfo)="data">
<!--            {{ `计划：${data.item.ext.premax} / 已入：${data.item.ext.inbound} / 可入：${data.item.ext.max}` }}-->
            {{ `计划：${data.item.ext.premax} / 已入：${data.item.ext.inbound}/ 待入：${data.item.ext.waitCount}` }}

          </template>
          <!-- Column: qty -->
          <template #cell(qty)="data">


            <ValidationProvider :rules="data.item.rule === undefined ? 'required|nonnegativeInteger' : data.item.rule"
                                name="数量"
                                #default="{ errors }" :vid="`qty_${data.index}`"
            >
              <b-form-input
                  size="sm"
                  @input="getMax(data.index)"
                  v-model="data.item.qty"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  @change="changeInput(data)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </template>

        </b-table>
      </ValidationObserver>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second
} from '@core/utils/filter'
import purchaseinbounditemUseList from './purchaseinbounditemUseList'
import purchaseinbounditemStore from './purchaseinbounditemStore'
import { useToast } from 'vue-toastification/composition'


export default {
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  props: {
    inbound_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  methods: {
    /*async validateAll() {
      let count = 0
      let items = this.dataList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`observer${i}`].validate()) {
          count++
        }
      }
      return count === items.length
    },*/
    async getMax(index) {
      let item = this.dataList[index]
      item.rule = undefined
      let id = item.inbounditem_id
      await store.dispatch('purchaseinbounditem/getMax', { itemid: id })
          .then(res => {
            let max = res.data.data
            item.rule = `required|nonnegativeInteger|max_value:${max}`
            this.dataList.splice(index, 1, item)
          })
    },
    getList(){
      this.$forceUpdate()
      return this.dataList;
    }
  },
  setup: function (props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseinbounditem')) store.registerModule('purchaseinbounditem', purchaseinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbounditem')) store.unregisterModule('purchaseinbounditem')
    })

    const checkMax = async function (item) {
      alert(JSON.stringify(item))
      let id = item.inbounditem_id
      let qty = item.qty
      await store.dispatch('purchaseinbounditem/getMax', { itemid: id })
          .then(res => {
            let max = res.data.data
            if (parseInt(qty) > parseInt(max)) {
              item.qty = max
            }
          })
    }
    const save = async function (item) {

      await checkMax(item)
      store.dispatch('purchaseinbounditem/updateOrderItem', {
        itemid: item.inbounditem_id,
        issel: item.is_sel,
        qty: item.qty,
        inventorytype:item.inventory_type
      })
          .then(res => {
            if (res.data.code === 0) {
              toast.success('数据已保存!')
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
    }
    const changeSelect = function (params,event) {
      params.is_sel = 1
      params.inventorytype = event.label
      params.inventory_type = event.value
    }

    // 自动选中
    const changeInput = function(data) {
      data.item.is_sel = 1
    }

    const changeCheckBox = function (params) {
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].inbounditem_id == params.inbounditem_id){
          this.dataList[i].is_sel = params.is_sel
        }
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI

    } = purchaseinbounditemUseList({
      inbound_id: props.inbound_id
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeInput,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      checkMax,
      save,
      changeSelect,
      changeCheckBox,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
